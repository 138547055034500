import { EventType, Tracker, TrackerData, TrackOptions, BaseContent } from './types';
import { uuidv4 } from './uuid';

const PULSE_WINDOW_KEY = 'pulse';

function pulse(...args: any): void {
    window[PULSE_WINDOW_KEY] =
        window[PULSE_WINDOW_KEY] ||
        function (...argz: any) {
            (window[PULSE_WINDOW_KEY].q = window[PULSE_WINDOW_KEY].q || []).push(argz);
        };

    window[PULSE_WINDOW_KEY].q = window[PULSE_WINDOW_KEY].q || [];
    window[PULSE_WINDOW_KEY].q.push(args);
}

export function trackEvent(data: TrackerData, trackOptions?: TrackOptions): void {
    addVerticalType(data);

    // Remove this call when the missing .id bug on Job object is fixed in pulse-sdk
    handleJobObjectQuirk(data);

    if (shouldAddPageContextToObject(data) && !trackOptions?.disableAddPageContext) {
        pulse('pageStateTracker', (pageStateTracker: Tracker) => {
            pageStateTracker.evaluateEventInputs().then((trackerState: TrackerData) => {
                if (data.object) {
                    // Attach '.object.page' from pageStateTracker.
                    data.object.page = {
                        ...trackerState.object,
                    };

                    // Add the ClassifiedAd object from the underlying
                    // page view to '.object.items' if the pageStateTracker
                    // has tracked a ClassifiedAd view.
                    //
                    // Merge it with the existing object in '.object.items'
                    // if one already exists.

                    if (trackerState.object?.type === 'ClassifiedAd' && !trackOptions?.disableAddClassifiedAdToItems) {
                        if (data.object?.items) {
                            const classifiedAdIndex = data.object.items.findIndex(
                                (item: BaseContent) => item.type === 'ClassifiedAd',
                            );
                            if (classifiedAdIndex > -1) {
                                data.object.items[classifiedAdIndex] = {
                                    ...trackerState.object,
                                    ...data.object.items[classifiedAdIndex],
                                };
                            } else {
                                data.object.items.push(trackerState.object);
                            }
                        } else {
                            data.object.items = [trackerState.object];
                        }
                    }
                }

                // Attach '.vertical' from pageStateTracker if not set.
                // It may have been set using 'setState()' for the default tracker,
                // but we set it here so we also can attach it automatically if
                // only trackPage() with vertical information has been used.
                if (!data.vertical?.name && trackerState.vertical?.name) {
                    data.vertical = trackerState.vertical;
                }

                data.pageViewId = trackerState.pageViewId;

                track('trackerEvent', data, trackOptions);
            });
        });
    } else {
        track('trackerEvent', data, trackOptions);
    }
}

export function trackPageView(data: TrackerData = {}, trackOptions?: TrackOptions): void {
    addVerticalType(data);

    // We need to explicitly generate the pageViewId so we can set it for both tracker instances,
    // and manually call 'track()' with '@type = View', to avoid the pageViewId being overwritten.
    //
    // Note: We'll need to wait for this commit
    // https://github.schibsted.io/spt-dataanalytics/pulse-event-builders/commit/38fd7e5484440eb227aea180160a2efb839ba11d
    // to be propagated to the pulse-autotracker transitively via the pulse-js-sdk
    // before it will work to explicitly provide a pageViewId.
    //
    data.pageViewId = uuidv4();
    data.type = 'View';

    // Set pageViewId for event tracker and store page state in page tracker.
    pulse('update', { pageViewId: data.pageViewId }, true);
    pulse('pageStateTracker.update', data, false);

    // Track page view using page tracker.
    pulse('pageStateTracker.track', 'trackerEvent', data, trackOptions);
}

export function setState(state: TrackerData): void {
    addVerticalType(state);
    pulse('update', state, true);
    pulse('pageStateTracker.update', state, true);
}

export function setPageState(pageState: TrackerData): void {
    addVerticalType(pageState);
    pulse('pageStateTracker.update', pageState, true);
}

export function getPageState(callback: (pageState: TrackerData) => void): void {
    pulse('pageStateTracker', (pageStateTracker: Tracker) => {
        pageStateTracker.evaluateEventInputs().then((trackerState: TrackerData) => {
            callback(trackerState);
        });
    });
}

export function getTracker(callback: (trackerRef: any) => void): void {
    pulse(callback);
}

export function getPageStateTracker(callback: (trackerRef: any) => void): void {
    pulse('pageStateTracker', callback);
}

export function trackExperiment(name: string, variant: string): void {
    if (name && variant) {
        const experimentId = `${name}_${variant}`;
        const data: TrackerData = {
            type: 'View',
            intent: 'Load',
            name: 'Experiment exposure',
            object: {
                id: 'ex_ev16',
                type: 'UIElement',
                elementType: 'Experiment',
            },
            experiments: [
                {
                    platform: 'finn',
                    id: `sdrn:finn:experiment:${experimentId}`,
                },
            ],
        };
        track('trackerEvent', data);
    }
}

function track(eventType: EventType, data: TrackerData = {}, trackOptions?: TrackOptions): void {
    pulse('track', eventType, data, trackOptions);
}

function addVerticalType(data: TrackerData): void {
    // Setting this statically in the initializer does not work,
    // since every call may have '.vertical' as input, and will overwrite
    // '.vertical' stored in the tracker state, since we don't
    // have any event builder for this (FINN specific) object.
    if (data.vertical) {
        data.vertical['@type'] = 'MarketplaceVertical';
    }
}

function handleJobObjectQuirk(data: TrackerData): void {
    // 'id' is not defined for the 'Job' object, but due to a quirk in the
    // pulse-event-builders library used by the pulse-js-dk, we must
    // provide an 'id' so that the 'type' property is preserved.
    // See https://sch-chat.slack.com/archives/C03NNRFC1/p1622021084042500
    if (data.object?.items) {
        for (const x of data.object?.items) {
            if (x.type === 'ClassifiedAd') {
                if (x.items) {
                    for (const y of x.items) {
                        if (y.type === 'Job') {
                            y.id = 'job';
                        }
                    }
                }
            }
        }
    }
}

function shouldAddPageContextToObject(data: TrackerData): boolean {
    return (
        data.object?.type === 'UIElement' ||
        data.object?.type === 'RecommendationList' ||
        data.object?.type === 'ListingItem' ||
        data.object?.type === 'RecommendationItem'
    );
}

export default {
    trackEvent,
    trackPageView,
    trackExperiment,
    setState,
    setPageState,
    getPageState,
    getTracker,
    getPageStateTracker,
};

export * from './types';
